@import '../../../styles/colours.module';
@import '../../../styles/typography';
.feature-tile{
  box-shadow: 3px 1px 7px 6px rgba(56, 55, 55, 0.09);
  display: grid;
  grid-template-rows: max-content 1fr;
  height: 100%;
  .tile-header{
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 10px;
    background-color: $darkPrimary;
    svg{
      height: 40px;
      width: 40px;
      color: $lightPrimary;
    }
    h2{

      color: $whitePrimary;
      font-size: 16px;
    }
  }
  .tile-content{
    display: grid;
    grid-template-rows: max-content 1fr;
    background-color: $whiteSecondary;
    img{
      width: 100%;
    }
    .points{
      padding: 10px;
      .categoryPoint{
        padding: 5px 0;
        display: flex;
        gap: 10px;
        align-items: center;

        svg{
          color: $lightPrimary;
          height: 30px;
          width: 30px;
        }
        h4{
          color: $darkPrimary;
          padding: 0;
          margin: 0;
          font-family: $senaryFont;
          font-size: 14px;
        }
      }
    }
  }
}

