@import './_variables.scss';
@mixin fixedWidth( $width: $websitePageWidth) {
  width: $width;
  display: grid;
  margin: 0 auto;
  box-sizing: border-box;
  @media screen and (max-width: $websitePageWidth) {
    width: 100%;
    margin: 0;
  }
}
