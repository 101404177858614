$lightPrimary: #00b1f6;
$lightSecondary: #00b1f6;
$darkPrimary: #1c3a8d;
$darkSecondary: #415ba6;
$blackPrimary: #000;
$blackSecondary: #000;
$whitePrimary: #fff;
$whiteSecondary: #fbfbfb;

:export {
  lightPrimary: $lightPrimary;
  lightSecondary: $lightSecondary;
  darkPrimary:$darkPrimary;
  darkSecondary:$darkSecondary;
  blackPrimary:$blackPrimary;
  blackSecondary:$blackPrimary;
  whitePrimary:$whitePrimary;
  whiteSecondary:$whiteSecondary;
}
