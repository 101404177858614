@import '../../../styles/variables';
@import '../../../styles/colours.module';
@import '../../../styles/_mixins.scss';
.feature-tiles-container{

  width: 100%;
  background-color: $whiteSecondary;
  >div{
    @include fixedWidth();
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 20px 0;
    grid-auto-rows: 1fr;
    @media screen and (max-width: $websitePageWidth) {
      padding: 20px;
    }
    @media screen and (max-width: 1080px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 760px) {
      grid-template-columns: 1fr;
      grid-auto-rows: initial;
    }
  }
}
