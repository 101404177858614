@import '../../../styles/mixins';
.app-footer{
  height: max-content;
  >div{
    >div{
      @include fixedWidth();
    }
    &:first-child{
      background-color: #dbdbdb;
      >div{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        padding: 30px;
        >div:nth-child(4){
          align-self: flex-end;
          justify-self: flex-end;
          img{
            height:60px;
          }
        }
        @media screen and (max-width: 1080px) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width: 760px) {
          grid-template-columns: 1fr;
        }
      }
    }
    &:nth-child(2){
      background-color: #525252;
      color: #bbbbbb;
      >div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        font-size: 12px;
      }
    }
  }
}
