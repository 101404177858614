@import '../../../styles/mixins';
@import '../../../styles/colours.module';
.app-header{
  padding: 10px 20px;
  background-color: $whiteSecondary;
  position: sticky;

  >div{
    @include fixedWidth();
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    img{
      max-height: 55px;
    }
    .contact{
      display: flex;
      flex-direction: column;
      gap: 10px;
      >div{
        display: flex;
        gap: 10px;
        align-items: center;
        svg{
          color:$lightPrimary;
        }
        a{
          text-decoration: none;
          color: $darkPrimary;
          h5{
            color: $darkPrimary;
            font-size: 14px;
            margin: 0;
          }
        }
      }
    }

    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
  }

}
